// extracted by mini-css-extract-plugin
export var beforeContainer = "Comparison-module--before-container--cf897";
export var beforeContainer1 = "Comparison-module--before-container-1--9750e";
export var beforeContainer1Alt = "Comparison-module--before-container-1-alt--43c9c";
export var beforeContainer1Reverse = "Comparison-module--before-container-1-reverse--e0b63";
export var beforeContainer2 = "Comparison-module--before-container-2--172bf";
export var beforeContainer2Hide = "Comparison-module--before-container-2-hide--b193c";
export var beforeContainer2Reverse = "Comparison-module--before-container-2-reverse--d0260";
export var beforeContainer2ReverseHide = "Comparison-module--before-container-2-reverse-hide--ba334";
export var bottom = "Comparison-module--bottom--9d999";
export var btnContainer = "Comparison-module--btn-container--e5730";
export var btnCta = "Comparison-module--btn-cta--4c1a1";
export var container = "Comparison-module--container--6ec92";
export var containerCta = "Comparison-module--container-cta--4b834";
export var containerSlider = "Comparison-module--container-slider--0bb10";
export var description = "Comparison-module--description--af1ca";
export var description2 = "Comparison-module--description-2--54d4c";
export var dividerSlideLeft = "Comparison-module--dividerSlideLeft--36054";
export var dividerSlideRight = "Comparison-module--dividerSlideRight--e2cea";
export var heading = "Comparison-module--heading--d0eb0";
export var heading1 = "Comparison-module--heading-1--9128b";
export var heading2 = "Comparison-module--heading-2--b0748";
export var imageSlideLeft = "Comparison-module--imageSlideLeft--22cf1";
export var imageSlideRight = "Comparison-module--imageSlideRight--2d2a3";
export var reverseImg = "Comparison-module--reverse-img--73277";
export var reverseTextContainer = "Comparison-module--reverse-text-container--5cdab";
export var set1 = "Comparison-module--set1--88185";
export var set1Img = "Comparison-module--set1-img--3fac4";
export var set1Img1 = "Comparison-module--set1-img-1--51d19";
export var set1TextContainer = "Comparison-module--set1-text-container--c8ed8";
export var set1TextContainer1 = "Comparison-module--set1-text-container-1--3c1e3";
export var set1TextContainer2 = "Comparison-module--set1-text-container-2--62a89";
export var slideLeft = "Comparison-module--slide-left--788a8";
export var slideRight = "Comparison-module--slide-right--fe2fd";
export var subheading = "Comparison-module--subheading--cb19c";
export var subheading2 = "Comparison-module--subheading-2--0c4b5";
export var title = "Comparison-module--title--5664a";
export var wrapper = "Comparison-module--wrapper--1add6";