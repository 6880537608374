import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import * as cx from './Before-And-After-Intro.module.scss'
const BeforeAndAfterIntro = ({
  wrapperClassName,
  textContent = { heading: 'Heading', description: 'Description' },
  bg = '',
}) => {
  return (
    <section
      className={clsx(wrapperClassName, [cx.wrapper])}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <p className={clsx(cx.text, cx.textHeading, 'heading-2')}>
        {textContent.heading}
      </p>
      <hr className={cx.line} />
      <p className={clsx(cx.text, cx.textDescription, 'default-body large')}>
        {textContent.description}
      </p>
    </section>
  )
}

BeforeAndAfterIntro.propTypes = {
  wrapperClassName: PropTypes.string,
  textContent: PropTypes.object,
  bg: PropTypes.string,
}

export default BeforeAndAfterIntro
