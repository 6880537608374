// extracted by mini-css-extract-plugin
export var card = "Read-More-module--card--d03e6";
export var card1 = "Read-More-module--card-1--ee457";
export var cardContainer = "Read-More-module--card-container--a9268";
export var contentContainer = "Read-More-module--content-container--cf632";
export var contentContainer1 = "Read-More-module--content-container-1--3289d";
export var heading = "Read-More-module--heading--fa271";
export var imageDesktop = "Read-More-module--image-desktop--45eb9";
export var imageMobile = "Read-More-module--image-mobile--f2819";
export var subtitle = "Read-More-module--subtitle--0fbd3";
export var title = "Read-More-module--title--97c9b";
export var wrapper = "Read-More-module--wrapper--9bd26";