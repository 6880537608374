import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import * as cx from './Banner.module.scss'
import Breadcrumbs from '@components/seo/Breadcrumbs'
import TabletLaptop from '../../../global/devices/TabletLaptop'
const Banner = ({
  wrapperClassName,
  bg,
  breadcrumbs,
  devices = { tabletSrc: '', laptopSrc: '' },
  textContent = { heading: 'Heading', subHeading: 'Subheading' },
}) => {
  return (
    <section
      className={clsx(wrapperClassName, [cx.wrapper])}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className={cx.breadcrumbsContainer}>
        <Breadcrumbs
          wrapperClassName={clsx(cx.breadcrumbs, 'global-breadcrumbs')}
          data={breadcrumbs}
        />
      </div>

      <p className={clsx('heading-1', cx.textHeading)}>{textContent.heading}</p>
      <p className={clsx('default-body large', cx.textSubheading)}>
        {textContent.subHeading}
      </p>
      <TabletLaptop
        wrapperClass={cx.devices}
        laptopSrc={devices.laptopSrc}
        tabletSrc={devices.tabletSrc}
      />
    </section>
  )
}

Banner.propTypes = {
  wrapperClassName: PropTypes.string,
  bg: PropTypes.string,
  breadcrumbs: PropTypes.any,
  devices: PropTypes.object,
  textContent: PropTypes.object,
}

export default Banner
