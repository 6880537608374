export const pageData = {
  title: 'Real Estate Website Before & After',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    {
      text: 'Our Work',
      url: '/',
    },
    {
      text: 'Real Estate Website Before & After',
      url: '/before-and-after/',
    },
  ],
  uri: '/digital-marketing/',
  buttonsHeight: { desktop: 1880, tablet: 2200, phone: 2880 },
  lcf: {
    titleClassName: 'heading-2',
  },
}

export const comparisonTextContent = [
  {
    set1: {
      heading: 'Ela Luxurious',
      subheading: 'elaluxurious.com',
      title: 'before',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar.',
    },
    set2: {
      heading: 'Ela Luxurious',
      subheading: 'elaluxurious.com',
      title: 'after',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar.',
    },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar elementum integer enim neque volutpat ac tincidunt. Et ligula ullamcorper malesuada proin libero nunc consequat. ',
  },
  {
    set1: {
      heading: 'Bobby Boyd',
      subheading: 'bobbyboydla.com',
      title: 'before',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar.',
    },
    set2: {
      heading: 'Bobby Boyd',
      subheading: 'bobbyboydla.com',
      title: 'after',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar.',
    },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar elementum integer enim neque volutpat ac tincidunt. Et ligula ullamcorper malesuada proin libero nunc consequat. ',
  },
  {
    set1: {
      heading: 'The Altman Brothers',
      subheading: 'thealtmanbrothers.com',
      title: 'before',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar.',
    },
    set2: {
      heading: 'The Altman Brothers',
      subheading: 'thealtmanbrothers.com',
      title: 'after',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar.',
    },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar elementum integer enim neque volutpat ac tincidunt. Et ligula ullamcorper malesuada proin libero nunc consequat. ',
  },
  {
    set1: {
      heading: 'The Oppenheim Group',
      subheading: 'TheOppenheimGroup.com',
      title: 'before',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar.',
    },
    set2: {
      heading: 'The Oppenheim Group',
      subheading: 'TheOppenheimGroup.com',
      title: 'after',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar.',
    },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar elementum integer enim neque volutpat ac tincidunt. Et ligula ullamcorper malesuada proin libero nunc consequat. ',
  },
  {
    set1: {
      heading: 'Aaron Kirman',
      subheading: 'AaronKirman.com',
      title: 'before',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar.',
    },
    set2: {
      heading: 'Aaron Kirman',
      subheading: 'AaronKirman.com',
      title: 'after',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar.',
    },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar elementum integer enim neque volutpat ac tincidunt. Et ligula ullamcorper malesuada proin libero nunc consequat. ',
  },
  {
    set1: {
      heading: 'Jills Zeder Group',
      subheading: 'jillszeder.com',
      title: 'before',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar.',
    },
    set2: {
      heading: 'Jills Zeder Group',
      subheading: 'jillszeder.com',
      title: 'after',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar.',
    },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar elementum integer enim neque volutpat ac tincidunt. Et ligula ullamcorper malesuada proin libero nunc consequat. ',
  },
  {
    set1: {
      heading: 'Williams & Williams',
      subheading: 'thewilliamsestates.com',
      title: 'before',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar.',
    },
    set2: {
      heading: 'Williams & Williams',
      subheading: 'thewilliamsestates.com',
      title: 'after',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar.',
    },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar elementum integer enim neque volutpat ac tincidunt. Et ligula ullamcorper malesuada proin libero nunc consequat. ',
  },
  {
    set1: {
      heading: 'The Carroll Group',
      subheading: 'thechadcarrollgroup.com',
      title: 'before',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar.',
    },
    set2: {
      heading: 'The Carroll Group',
      subheading: 'thechadcarrollgroup.com',
      title: 'after',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar.',
    },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar elementum integer enim neque volutpat ac tincidunt. Et ligula ullamcorper malesuada proin libero nunc consequat. ',
  },
  {
    set1: {
      heading: 'Quiana Watson',
      subheading: 'QuianaWatson.com',
      title: 'before',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar.',
    },
    set2: {
      heading: 'Quiana Watson',
      subheading: 'QuianaWatson.com',
      title: 'after',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar.',
    },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar elementum integer enim neque volutpat ac tincidunt. Et ligula ullamcorper malesuada proin libero nunc consequat. ',
  },
  {
    set1: {
      heading: 'Billy Nash',
      subheading: 'thechadcarrollgroup.com',
      title: 'before',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar.',
    },
    set2: {
      heading: 'Billy Nash',
      subheading: 'thechadcarrollgroup.com',
      title: 'after',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar.',
    },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar elementum integer enim neque volutpat ac tincidunt. Et ligula ullamcorper malesuada proin libero nunc consequat. ',
  },
  {
    set1: {
      heading: 'Tracy Tutor',
      subheading: 'tracytutor.com',
      title: 'before',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar.',
    },
    set2: {
      heading: 'Tracy Tutor',
      subheading: 'tracytutor.com',
      title: 'after',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar.',
    },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar elementum integer enim neque volutpat ac tincidunt. Et ligula ullamcorper malesuada proin libero nunc consequat. ',
  },
  {
    set1: {
      heading: 'Sharlene Chang',
      subheading: 'SharleneChang.com',
      title: 'before',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar.',
    },
    set2: {
      heading: 'Sharlene Chang',
      subheading: 'SharleneChang.com',
      title: 'after',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar.',
    },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus pulvinar elementum integer enim neque volutpat ac tincidunt. Et ligula ullamcorper malesuada proin libero nunc consequat. ',
  },
]
