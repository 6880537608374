import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import parse from 'html-react-parser'
import { removeElementTags } from '@utils/removeElementTags'

import FormFreeConsultation from '../../FreeConsultation'
import ElementImageFile from '@components/global/element/ImageFile'
import * as cx from './LCF05.module.scss'

const LeadContentForm05 = ({
  model,
  title,
  subtitle,
  submitLabel,
  leadName,
  layout = 'default',
  formLocation,
}) => {
  return (
    <section
      className={clsx(
        cx.wrapper,
        (layout === 'A' || layout === 'B') && cx.layoutA,
        layout === 'B' && cx.layoutB,
        'lcf05-wrapper'
      )}
    >
      <div className={cx.background}></div>
      <ElementImageFile
        className={clsx(cx.model, 'image-model', 'lfc05-model')}
        src={model}
        alt={title}
        lazy={true}
      />
      <div className={clsx(cx.formContainer, 'lfc05-container')}>
        <h2>
          {layout !== 'B' && (
            <>
              <span className="subtitle-7">{parse(subtitle)}</span>
              <span className="heading-2">{parse(title)}</span>
            </>
          )}
          {layout === 'B' && (
            <>
              <span className="heading-5">{parse(title)}</span>
              <span className="subtitle-5">{parse(subtitle)}</span>
            </>
          )}
        </h2>

        <div className={clsx(cx.form, 'lfc05-form')}>
          <FormFreeConsultation
            submitLabel={submitLabel}
            leadName={submitLabel}
            isEmailPhoneMerger={true}
            isMessageTextarea={true}
            formLocation={formLocation}
          />
        </div>

        {layout === 'B' && (
          <div className={clsx(cx.callUs)}>
            <a href="tel:+1.877.910.5117" className="subtitle-2">
              Or Call 1.877.910.5117
            </a>
          </div>
        )}
      </div>
    </section>
  )
}

LeadContentForm05.propTypes = {
  model: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  submitLabel: PropTypes.string,
  leadName: PropTypes.string,
  layout: PropTypes.string,
  formLocation: PropTypes.string,
}

export default LeadContentForm05
