import React from 'react'

import LayoutInnerPage from '@src/layouts/InnerPage'
import Seo from '@components/seo/Index'
import {
  pageData as page,
  comparisonTextContent,
} from '@src/data/BeforeAndAfter'
import Banner from '../../../components/pages/before-and-after/Banner'
import useBeforeAndAfterMetaData from '../../../hooks/before-and-after'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import { getImage } from 'gatsby-plugin-image'
import useMedia from '../../../hooks/useMedia'
import useWindowSize from '@hooks/useWindowSize'
import BeforeAndAfterIntro from '../../../components/pages/before-and-after/Before-And-After-Intro'
import Comparison from '../../../components/global/Comparison'
import ReadMore from '../../../components/pages/before-and-after/Read-More'
import LeadContentForm05 from '../../../components/global/forms/LeadContactForm/LCF05'
import ModalForm from '../../../components/global/modal/Form'
import FormSignUp from '../../../components/global/forms/SignUp'
import * as cx from './BeforeAndAfter.module.scss'

const RealEstateDigitalMarketing = () => {
  const {
    BannerBG,
    ScreenTablet,
    ScreenLaptop,
    Set1Img1,
    Set1Img2,
    Set2Img1,
    Set2Img2,
    Set3Img1,
    Set3Img2,
    Set4Img1,
    Set4Img2,
    Set5Img1,
    Set5Img2,
    Set6Img1,
    Set6Img2,
    Set7Img1,
    Set7Img2,
    Set8Img1,
    Set8Img2,
    Set9Img1,
    Set9Img2,
    Set10Img1,
    Set10Img2,
    Set11Img1,
    Set11Img2,
    Set12Img1,
    Set12Img2,
    ContentBG,
    IntroBG,
    Model,
    ModelPhone,
  } = useBeforeAndAfterMetaData()

  const isDesktopAlt = useMedia('screen and (min-width: 1211px)')
  const isTablet = useMedia(
    'screen and (min-width: 815px) and (max-width: 1023px)'
  )
  const isPhone = useWindowSize().width <= 767

  const compareImgs = [
    [Set1Img2, Set1Img1],
    [Set2Img1, Set2Img2],
    [Set3Img1, Set3Img2],
    [Set4Img1, Set4Img2],
    [Set5Img1, Set5Img2],
    [Set6Img1, Set6Img2],
    [Set7Img1, Set7Img2],
    [Set8Img1, Set8Img2],
    [Set9Img1, Set9Img2],
    [Set10Img1, Set10Img2],
    [Set11Img1, Set11Img2],
    [Set12Img1, Set12Img2],
  ]

  const pageTextContent = {
    banner: {
      heading: 'Real Estate Website Transformations',
      subHeading: 'We Make Your Agent Sites Look Amazing',
    },
    comparisonIntro: {
      heading: 'Before & After',
      description: (
        <span>
          Our team of designers work diligently to provide you with beautiful
          real estate websites that are custom tailored to your needs.{' '}
          {isDesktopAlt && <br />}See the Before & After samples below of some
          of our sites. Notice the value and transformation that Agent Image can
          offer in comparison {isDesktopAlt || (isTablet && <br />)}to other
          mass-produced template website companies. If you’re interested in
          customized design, please consider our Semi-Custom{' '}
          {isDesktopAlt && <br />}
          Websites and ImagineStudio Websites.
        </span>
      ),
    },
    lcf: {
      layout: 'A',
      title: 'Free Consultation',
      subtitle: 'GET IN TOUCH WITH US FOR A',
      submitLabel: 'I want a stunning website!',
    },
  }
  const modalContent = {
    title: 'Sign Up Today For A <br>Free Consultation',
    description:
      'See how Agent Image can help you grow your real estate business.',
    template: 'm1',
    type: 'b',
    submitLabel: 'Get Started Today',
  }

  const modalFunc = React.useRef()
  return (
    <LayoutInnerPage hasWhiteTransparentNav wrapperClassName={cx.wrapper}>
      <Seo title={page.title} uri={page.uri} />
      <Banner
        breadcrumbs={page.breadcrumbs}
        bg={extractImage(BannerBG)}
        devices={{
          tabletSrc: extractImage(ScreenTablet),
          laptopSrc: extractImage(ScreenLaptop),
        }}
        textContent={{
          heading: pageTextContent.banner.heading,
          subHeading: pageTextContent.banner.subHeading,
        }}
      />
      <BeforeAndAfterIntro
        bg={extractImage(IntroBG)}
        textContent={{
          heading: pageTextContent.comparisonIntro.heading,
          description: pageTextContent.comparisonIntro.description,
        }}
      />
      {comparisonTextContent.map((data, key) => (
        <Comparison
          key={'comparison-' + key}
          reverse={key % 2 !== 0 ? true : false}
          bg={extractImage(ContentBG)}
          images={{
            image1: extractImage(compareImgs[key][0]),
            image2: extractImage(compareImgs[key][1]),
          }}
          textContent={{
            set1: {
              heading: data.set1.heading,
              subheading: data.set1.subheading,
              title: data.set1.title,
              description: data.set1.description,
            },
            set2: {
              heading: data.set2.heading,
              subheading: data.set2.subheading,
              title: data.set2.title,
              description: data.set2.description,
            },
            description: data.description,
          }}
          itemKey={key}
          onBtnClick={(e) => modalFunc.current.openModal()}
        />
      ))}
      <ModalForm
        childFunc={modalFunc}
        title={modalContent.title}
        description={modalContent.description}
        template={modalContent.template}
        type={modalContent.type}
      >
        <FormSignUp
          submitLabel={modalContent.submitLabel}
          messageLabel={modalContent.messageLabel}
        />
      </ModalForm>
      <ReadMore />
      <LeadContentForm05
        layout={pageTextContent.lcf.layout}
        model={getImage(isPhone ? ModelPhone : Model)}
        title={pageTextContent.lcf.title}
        subtitle={pageTextContent.lcf.subtitle}
        submitLabel={pageTextContent.lcf.submitLabel}
      />
    </LayoutInnerPage>
  )
}

export default RealEstateDigitalMarketing
