import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import * as cx from './Comparison.module.scss'
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from 'react-compare-slider'
import SvgArrowComparison from '../../../svg/arrowComparison.inline'
import useMedia, { media } from '../../../hooks/useMedia'
import { Button } from '../buttons/Buttons'

const Comparison = ({
  wrapperClassName,
  bg,
  images = { image1, image2 },
  reverse = true,
  textContent = {
    set1: {
      heading: 'Heading',
      subheading: 'Subheading',
      title: 'Title',
      description: 'Description',
    },
    set2: {
      heading: 'Heading',
      subheading: 'Subheading',
      title: 'Title',
      description: 'Description',
    },
    description: 'Description',
  },
  onBtnClick,
  itemKey,
}) => {
  const isDesktop = useMedia(media.desktop)
  const isTablet = useMedia(media.tablet)
  const sliderPos = isDesktop
    ? reverse
      ? 50
      : 25
    : isTablet
    ? reverse
      ? 60
      : 25
    : 33
  const [sliderPosition, setSliderPosition] = useState(sliderPos)

  const handlePositionChange = (targetPosition) => {
    if (sliderPosition == targetPosition) return
    const element = document.getElementsByClassName('past-img')[itemKey]
    const sliderWidth = element.parentElement.offsetWidth
    const imgSlideRightAnimation = element?.parentElement.animate(
      [{ clip: ` rect(auto, ${sliderWidth}px, auto, auto)` }],
      {
        duration: 1000,
        easing: 'cubic-bezier(0.075, 0.82, 0.165, 1)',
        fill: 'forwards',
      }
    )
    imgSlideRightAnimation.pause()

    if (targetPosition === 0) {
      element?.parentElement.classList.add(cx.slideLeft)
    } else {
      imgSlideRightAnimation.play()
    }
    element?.parentElement.nextElementSibling.classList.add(
      targetPosition === 0 ? cx.slideLeft : cx.slideRight
    )
    setTimeout(() => {
      setSliderPosition(targetPosition)

      if (targetPosition === 0) {
        element?.parentElement.classList.remove(cx.slideLeft)
      } else {
        imgSlideRightAnimation.cancel()
      }

      element?.parentElement.nextElementSibling.classList.remove(
        targetPosition === 0 ? cx.slideLeft : cx.slideRight
      )
    }, 1100)
  }

  return (
    <section
      className={clsx(wrapperClassName, [cx.wrapper])}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <ReactCompareSlider
        onlyHandleDraggable
        position={sliderPosition}
        className={clsx(cx.container, cx.containerSlider)}
        handle={
          <div className={clsx(cx.btnContainer)}>
            <button>
              <SvgArrowComparison />
            </button>
          </div>
        }
        itemOne={
          <>
            <ReactCompareSliderImage
              className={clsx(
                cx.set1Img,
                reverse && cx.reverseImg,
                cx.set1Img1,
                'past-img'
              )}
              srcSet={images.image1}
              alt="Image one"
            />

            <div
              className={clsx(
                cx.set1TextContainer,
                reverse && cx.reverseTextContainer,
                cx.set1TextContainer1
              )}
            >
              <p className={clsx('subtitle-2', cx.heading, cx.heading1)}>
                {textContent.set1.heading}
              </p>
              <p className={clsx('lozenge', cx.subheading, cx.subheading2)}>
                {textContent.set1.subheading}
              </p>
              <div className={clsx(cx.bottom)}>
                <p className={clsx('small-heading', cx.title)}>
                  {textContent.set1.title}
                </p>
                <p className={clsx('default-body large', cx.description)}>
                  {textContent.set1.description}
                </p>
              </div>
            </div>
          </>
        }
        itemTwo={
          <>
            <ReactCompareSliderImage
              className={clsx(cx.set1Img, reverse && cx.reverseImg)}
              srcSet={images.image2}
              alt="Image two"
            />
            <button
              className={clsx(
                cx.beforeContainer,
                cx.beforeContainer1,
                reverse && cx.beforeContainer1Reverse
              )}
              onClick={() => handlePositionChange(100)}
            >
              <p className="lozenge">before</p>
            </button>
            <button
              className={clsx(
                cx.beforeContainer,
                cx.beforeContainer2,
                cx.beforeContainer2Reverse,
                !reverse && cx.beforeContainer2ReverseHide
              )}
              onClick={() => handlePositionChange(0)}
            >
              <p className="lozenge">after</p>
            </button>
            <div
              className={clsx(
                cx.set1TextContainer,
                reverse && cx.reverseTextContainer,
                cx.set1TextContainer2
              )}
            >
              <button
                className={clsx(cx.beforeContainer, cx.beforeContainer1Alt)}
                onClick={() => handlePositionChange(100)}
              >
                <p className="lozenge">before</p>
              </button>
              <button
                className={clsx(
                  cx.beforeContainer,
                  cx.beforeContainer2,
                  reverse && cx.beforeContainer2Hide
                )}
                onClick={() => handlePositionChange(0)}
              >
                <p className="lozenge">after</p>
              </button>
              <p className={clsx('subtitle-2', cx.heading, cx.heading2)}>
                {textContent.set2.heading}
              </p>
              <p className={clsx('lozenge', cx.subheading, cx.subheading2)}>
                {textContent.set2.subheading}
              </p>
              <div className={clsx(cx.bottom)}>
                <p className={clsx('small-heading', cx.title)}>
                  {textContent.set2.title}
                </p>
                <p
                  className={clsx(
                    'default-body large',
                    cx.description,
                    cx.description2
                  )}
                >
                  {textContent.set2.description}
                </p>
              </div>
            </div>
          </>
        }
        onPositionChange={(val) => setSliderPosition(val)}
      />
      <div className={clsx(cx.container, cx.containerCta)}>
        <p className={clsx('default-body', cx.description)}>
          {textContent.description}
        </p>
        <Button
          text="Transform my website"
          wrapperClassName={cx.btnCta}
          onClick={() => onBtnClick()}
        />
      </div>
    </section>
  )
}

Comparison.propTypes = {
  wrapperClassName: PropTypes.string,
  bg: PropTypes.string,
  images: PropTypes.object,
  reverse: PropTypes.bool,
  textContent: PropTypes.object,
  onBtnClick: PropTypes.any,
}

export default Comparison
