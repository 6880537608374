import React from 'react'

import clsx from 'clsx'
import * as cx from './Read-More.module.scss'
import useBeforeAndAfterMetaData from '../../../../hooks/before-and-after'
import useWindowSize from '@hooks/useWindowSize'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
const ReadMore = ({}) => {
  const {
    Card1Img,
    Card2Img,
    Card2ImgMobile,
    ReadMoreBG,
    ReadMoreBGTablet,
    ReadMoreBGPhone,
  } = useBeforeAndAfterMetaData()
  const isDesktop = useWindowSize().width >= 1024
  const isPhone = useWindowSize().width <= 767

  return (
    <section
      className={clsx([cx.wrapper])}
      style={{
        backgroundImage: `url(${extractImage(
          isDesktop ? ReadMoreBG : isPhone ? ReadMoreBGPhone : ReadMoreBGTablet
        )})`,
      }}
    >
      <p className={clsx(cx.heading, 'heading-5')}>Ready to Read More?</p>

      <div className={cx.cardContainer}>
        <div className={clsx(cx.card, cx.card1)}>
          <p className={clsx('subtitle-7', cx.subtitle)}>Learn More About</p>
          <p className={clsx('heading-3', cx.title)}>Our Design Process</p>
          <div className={clsx(cx.contentContainer, cx.contentContainer1)}>
            <img src={extractImage(Card1Img)}></img>
          </div>
        </div>
        <div className={cx.card}>
          <p className={clsx('subtitle-7', cx.subtitle)}>See More Of Our</p>
          <p className={clsx('heading-3', cx.title)}>Success Stories</p>
          <div className={clsx(cx.contentContainer)}>
            <img
              src={extractImage(Card2Img)}
              className={clsx(cx.imageDesktop)}
            ></img>
            <img
              src={extractImage(Card2ImgMobile)}
              className={clsx(cx.imageMobile)}
            ></img>
          </div>
        </div>
      </div>
    </section>
  )
}

ReadMore.propTypes = {}

export default ReadMore
