import { useStaticQuery, graphql } from 'gatsby'

const useBeforeAndAfterMetaData = () => {
  const query = useStaticQuery(graphql`
    query beforeAndAfterMetaData {
      BannerBG: file(
        relativePath: { eq: "before-and-after/banner/banner-bg.png" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      ScreenTablet: file(
        relativePath: { eq: "before-and-after/banner/screen-tablet.png" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      ScreenLaptop: file(
        relativePath: { eq: "before-and-after/banner/screen-laptop.png" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Set1Img1: file(
        relativePath: { eq: "before-and-after/comparison/set1-img1.jpg" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Set1Img2: file(
        relativePath: { eq: "before-and-after/comparison/set1-img2.jpg" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Set2Img1: file(
        relativePath: { eq: "before-and-after/comparison/set2-img1.jpg" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Set2Img2: file(
        relativePath: { eq: "before-and-after/comparison/set2-img2.jpg" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Set3Img1: file(
        relativePath: { eq: "before-and-after/comparison/set3-img1.jpg" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Set3Img2: file(
        relativePath: { eq: "before-and-after/comparison/set3-img2.jpg" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Set4Img1: file(
        relativePath: { eq: "before-and-after/comparison/set4-img1.jpg" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Set4Img2: file(
        relativePath: { eq: "before-and-after/comparison/set4-img2.jpg" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Set5Img1: file(
        relativePath: { eq: "before-and-after/comparison/set5-img1.jpg" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Set5Img2: file(
        relativePath: { eq: "before-and-after/comparison/set5-img2.jpg" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Set6Img1: file(
        relativePath: { eq: "before-and-after/comparison/set6-img1.jpg" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Set6Img2: file(
        relativePath: { eq: "before-and-after/comparison/set6-img2.jpg" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Set7Img1: file(
        relativePath: { eq: "before-and-after/comparison/set7-img1.jpg" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Set7Img2: file(
        relativePath: { eq: "before-and-after/comparison/set7-img2.png" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Set8Img1: file(
        relativePath: { eq: "before-and-after/comparison/set8-img1.jpg" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Set8Img2: file(
        relativePath: { eq: "before-and-after/comparison/set8-img2.jpg" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Set9Img1: file(
        relativePath: { eq: "before-and-after/comparison/set9-img1.jpg" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Set9Img2: file(
        relativePath: { eq: "before-and-after/comparison/set9-img2.png" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Set10Img1: file(
        relativePath: { eq: "before-and-after/comparison/set10-img1.jpg" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Set10Img2: file(
        relativePath: { eq: "before-and-after/comparison/set10-img2.jpg" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Set11Img1: file(
        relativePath: { eq: "before-and-after/comparison/set11-img1.jpg" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Set11Img2: file(
        relativePath: { eq: "before-and-after/comparison/set11-img2.jpg" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Set12Img1: file(
        relativePath: { eq: "before-and-after/comparison/set12-img1.jpg" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Set12Img2: file(
        relativePath: { eq: "before-and-after/comparison/set12-img2.jpg" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      ContentBG: file(
        relativePath: { eq: "before-and-after/comparison/content-bg.png" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      IntroBG: file(
        relativePath: { eq: "before-and-after/comparison/intro-bg.png" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Card1Img: file(
        relativePath: { eq: "before-and-after/read-more/devices.png" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Card2Img: file(
        relativePath: { eq: "before-and-after/read-more/stories.png" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Card2ImgMobile: file(
        relativePath: { eq: "before-and-after/read-more/stories-mobile.png" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      Model: file(relativePath: { eq: "before-and-after/lcf/model.png" }) {
        id
        ...BeforeAndAfterImageOptimize
      }
      ModelPhone: file(
        relativePath: { eq: "before-and-after/lcf/model-phone.jpg" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      ReadMoreBG: file(
        relativePath: { eq: "before-and-after/read-more/read-more-bg.png" }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      ReadMoreBGTablet: file(
        relativePath: {
          eq: "before-and-after/read-more/read-more-bg-tablet.jpg"
        }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
      ReadMoreBGPhone: file(
        relativePath: {
          eq: "before-and-after/read-more/read-more-bg-phone.jpg"
        }
      ) {
        id
        ...BeforeAndAfterImageOptimize
      }
    }

    fragment BeforeAndAfterImageOptimize on File {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          formats: [AUTO, WEBP]
        )
      }
    }
  `)

  return query
}

export default useBeforeAndAfterMetaData
