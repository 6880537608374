import React from 'react'
import PropTypes from 'prop-types'

const SvgArrowComparison = ({ width = 72, height = 35.13 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 73 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.525635"
        y="0.667969"
        width="72"
        height="35.1304"
        rx="17.5652"
        fill="white"
      />
      <path
        d="M21.4488 11.0585C21.262 11.058 21.0775 11.0918 20.9087 11.1574C20.74 11.223 20.5913 11.3188 20.4736 11.4377L14.435 17.5861C14.2512 17.7695 14.1506 17.9995 14.1506 18.2369C14.1506 18.4742 14.2512 18.7042 14.435 18.8876L20.6862 25.0361C20.8984 25.2453 21.2033 25.3769 21.5339 25.4019C21.8645 25.4269 22.1937 25.3432 22.449 25.1693C22.7043 24.9953 22.8649 24.7454 22.8953 24.4744C22.9258 24.2034 22.8237 23.9337 22.6115 23.7244L17.023 18.2317L22.424 12.7391C22.5769 12.5887 22.674 12.4055 22.7038 12.2113C22.7337 12.017 22.695 11.8198 22.5924 11.643C22.4899 11.4662 22.3276 11.3172 22.125 11.2136C21.9223 11.11 21.6876 11.0562 21.4488 11.0585Z"
        fill="#4F5152"
      />
      <path
        d="M51.6025 25.4064C51.7892 25.4069 51.9738 25.3731 52.1425 25.3075C52.3113 25.2419 52.46 25.1461 52.5776 25.0272L58.6162 18.8787C58.8001 18.6953 58.9006 18.4653 58.9006 18.228C58.9006 17.9906 58.8001 17.7606 58.6162 17.5773L52.3651 11.4288C52.1529 11.2195 51.8479 11.0879 51.5174 11.0629C51.1868 11.0379 50.8576 11.1216 50.6023 11.2956C50.347 11.4695 50.1864 11.7195 50.1559 11.9904C50.1254 12.2614 50.2275 12.5312 50.4398 12.7405L56.0283 18.2331L50.6273 23.7258C50.4744 23.8762 50.3773 24.0593 50.3474 24.2536C50.3176 24.4478 50.3562 24.645 50.4588 24.8218C50.5614 24.9986 50.7236 25.1476 50.9263 25.2512C51.129 25.3548 51.3636 25.4086 51.6025 25.4064Z"
        fill="#4F5152"
      />
    </svg>
  )
}

SvgArrowComparison.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

export default SvgArrowComparison
