import React from 'react'
import PropTypes from 'prop-types'
import DeviceLaptopAlt from '../LaptopAlt'
import * as cx from './TabletLaptop.module.scss'
import DeviceTabletAlt from '../TabletAlt'

const TabletLaptop = ({
  wrapperClass = '',
  tabletSrc,
  laptopSrc,
  alt = '',
  lazy = true,
}) => {
  return (
    <div className={`${cx.wrapper} ${wrapperClass}`}>
      <canvas
        width={1080}
        height={324}
        className={`${cx.placeholder}`}
      ></canvas>
      <DeviceTabletAlt
        className={`${cx.tablet} ipad-landscape`}
        imageSrc={tabletSrc}
        imageClassName={cx.screenTablet}
        lazy={lazy}
      />
      <DeviceLaptopAlt
        className={`${cx.laptop} ipad-laptop`}
        imageSrc={laptopSrc}
        lazy={lazy}
      />
    </div>
  )
}

TabletLaptop.propTypes = {
  wrapperClass: PropTypes.string,
  tabletSrc: PropTypes.string,
  laptopSrc: PropTypes.string,
  alt: PropTypes.string,
  lazy: PropTypes.bool,
}

export default TabletLaptop
