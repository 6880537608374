import React from 'react'
import PropTypes from 'prop-types'
import { getImage } from 'gatsby-plugin-image'
import { modifyImageKeys } from '@utils/modifyImageKeys'
import ElementImage from '@components/global/element/Image'
import ElementImageFile from '@components/global/element/ImageFile'
import * as cx from './TabletAlt.module.scss'
import clsx from 'clsx'

const DeviceTabletAlt = ({
  imageSrc,
  className = '',
  imageClassName,
  rotate = false,
  imagePlaceholder = 'https://resources.agentimage.com/redesign/local/tablet-alt.png',
  imageFile,
  imageFileAlt = '',
  splideLazyload = false,
  lazy = true,
  width = 720,
  height = 520,
}) => {
  let image = null

  if (imageFile !== null && imageFile !== undefined) {
    if (imageFile.modified) {
      image = modifyImageKeys(imageFile)
    } else {
      image = getImage(imageFile)
    }
  }

  return (
    <div className={`${cx.wrapper} ${className} ${rotate ? cx.rotate : ''}`}>
      <canvas
        width={rotate ? height : width}
        height={rotate ? width : height}
        style={{
          backgroundImage: `url(${
            rotate
              ? 'https://resources.agentimage.com/redesign/local/tablet-alt-landscape.png'
              : imagePlaceholder
          })`,
        }}
        className={`${cx.placeholder} global-devices-tabletalt-placeholder`}
      ></canvas>
      {image === null && (
        <canvas
          width={rotate ? height : width}
          height={rotate ? width : height}
          style={{ backgroundImage: `url(${imageSrc})` }}
          className={clsx(cx.canvas, imageClassName)}
        ></canvas>
      )}

      {image !== null && splideLazyload && (
        <ElementImage
          src={image?.images.fallback.src}
          width={rotate ? height : width}
          height={rotate ? width : height}
          alt={imageFileAlt}
          srcSet={image?.images.fallback.srcSet}
          sizes={image?.images.fallback.sizes}
          splideLazyload={splideLazyload}
          className={cx.image}
        />
      )}

      {image !== null && !splideLazyload && (
        <ElementImageFile
          src={image}
          width={rotate ? height : width}
          height={rotate ? width : height}
          alt={imageFileAlt}
          className={cx.image}
          lazy={lazy}
        />
      )}
    </div>
  )
}

DeviceTabletAlt.propTypes = {
  imageSrc: PropTypes.string,
  className: PropTypes.string,
  rotate: PropTypes.bool,
  imagePlaceholder: PropTypes.string,
  imageFile: PropTypes.object,
  imageFileAlt: PropTypes.string,
  splideLazyload: PropTypes.bool,
  lazy: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  imageClassName: PropTypes.string,
}

export default DeviceTabletAlt
