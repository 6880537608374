import React from 'react'
import PropTypes from 'prop-types'
import { getImage } from 'gatsby-plugin-image'
import { modifyImageKeys } from '@utils/modifyImageKeys'

import ElementImage from '@components/global/element/Image'
import ElementImageFile from '@components/global/element/ImageFile'
import * as cx from './LaptopAlt.module.scss'

const DeviceLaptopAlt = ({
  imageSrc,
  className = '',
  imagePlaceholder = 'https://resources.agentimage.com/redesign/local/laptop-alt-placeholder.png',
  imageFile,
  imageFileAlt = '',
  splideLazyload = false,
  lazy = true,
  width = 900,
  height = 513,
}) => {
  let image = null

  if (imageFile !== null && imageFile !== undefined) {
    if (imageFile.modified) {
      image = modifyImageKeys(imageFile)
    } else {
      image = getImage(imageFile)
    }
  }

  return (
    <div className={`${cx.wrapper} ${className}`}>
      <canvas
        width={width}
        height={height}
        style={{ backgroundImage: `url(${imagePlaceholder})` }}
      ></canvas>
      {image === null && (
        <canvas
          width={width}
          height={height}
          style={{ backgroundImage: `url(${imageSrc})` }}
          className={cx.canvas}
        ></canvas>
      )}
      {image !== null && splideLazyload && (
        <ElementImage
          src={image?.images.fallback.src}
          width={width}
          height={height}
          alt={imageFileAlt}
          srcSet={image?.images.fallback.srcSet}
          sizes={image?.images.fallback.sizes}
          splideLazyload={splideLazyload}
          className={cx.image}
        />
      )}
      {image !== null && !splideLazyload && (
        <ElementImageFile
          src={image}
          width={width}
          height={height}
          alt={imageFileAlt}
          className={cx.image}
          lazy={lazy}
        />
      )}
    </div>
  )
}

DeviceLaptopAlt.propTypes = {
  imageSrc: PropTypes.string,
  className: PropTypes.string,
  imagePlaceholder: PropTypes.string,
  imageFile: PropTypes.object,
  imageFileAlt: PropTypes.string,
  splideLazyload: PropTypes.bool,
  lazy: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
}

export default DeviceLaptopAlt
